
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import NarrowPage from "../components/NarrowPage";
export default function Custom404() {
    return (<NarrowPage title={404}>
      <div className="flex items-center justify-center text-center p-12 font-bold text-2xl">
        Page Not Found
      </div>
    </NarrowPage>);
}

    async function __Next_Translate__getStaticProps__194e0262990__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194e0262990__ as getStaticProps }
  